<template>
  <header class="sticky-top" :class="{ 'with-shadow': withShadow }">
    <navbar-inner
      :show-zasilkovna-logo="showZasilkovnaLogo"
      :show-shipment-tracking="showShipmentTracking"
      :lang-menu-options="langMenuOptions"
      :is-mobile="isMobile"
    />
  </header>
</template>

<script lang="ts" setup>
import { useRoute } from 'nuxt/app'
import { computed, defineAsyncComponent } from 'vue'
import { langFlags, langNames } from '~common/helpers'
import type { AtomDropdownMenuItemProps, NavbarProps } from '~common/types'
import i18nConfig, { locales } from '~config/i18nConfig.ts'

const NavbarInner = defineAsyncComponent(() => import('~components/navbar/NavbarInner.vue'))

const route = useRoute()
const localePath = useLocalePath()

const props = defineProps<NavbarProps>()

const withShadow = computed(() => {
  return props.showShipmentTracking && !props.isMobile
})

const langMenuOptions = computed<AtomDropdownMenuItemProps[]>(() => {
  return Object.keys(langFlags)
    .filter((key) => locales.find((locale) => locale.code === key))
    .map((key) => {
      const component = langFlags[key as keyof typeof langFlags]
      const title = langNames[key as keyof typeof langFlags]

      let to = localePath(route.path, key)
      if (i18nConfig.defaultLocale === key) {
        to = `/${i18nConfig.defaultLocale}${to === '/' ? '' : `${to}`}`
      }

      return {
        id: key,
        title,
        component,
        to,
      }
    })
})
</script>

<style lang="scss" scoped>
header {
  height: 80px;
}

header.sticky-top {
  z-index: 5;
}

header.with-shadow {
  box-shadow: 7px 5px 35px 0 rgba(0, 0, 0, 0.05);
}

@media (min-width: $media-md) {
  header {
    height: 71px;
  }
}

@media (min-width: $media-lg) {
  header {
    padding-left: calc(100vw - 100%);
  }
}
</style>
